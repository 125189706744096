/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Urbanist', sans-serif;
  /* font-optical-sizing: auto; */
  /* font-size: 18px; */
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.crafted-by {
  color: #999;
  font-family: Urbanist;
  font-size: 0.88889rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.33333rem; /* 150% */
  letter-spacing: 0.02667rem;
}
.crafted-by-luwak {
  color: #333;
  font-family: Urbanist;
  font-size: 0.88889rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33333rem; /* 150% */
  letter-spacing: 0.01778rem;
}

@media (max-width: 576px) {
  body {
    font-size: 11px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  body {
    font-size: 11px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  body {
    font-size: 13px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  body {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  body {
    font-size: 18px;
  }
}
